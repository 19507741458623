import AOS from 'aos';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Switch, useLocation } from 'react-router-dom';
import './css/style.scss';

import PageNotFound from './pages/PageNotFound';
import ADMIN_ROUTES from './routes/AdminRoutes';
import SETTINGS_ROUTES from './routes/SettingsRoutes';
import DASHBOARD_ROUTES from './routes/DashboardRoutes';
import DOCUMENTS_ROUTES from './routes/DocumentsRoutes';
import GENERIC_ROUTES from './routes/GenericRoutes';
import CORPORATE_ROUTES from './routes/CorporateRoutes';

const ROUTES = [];

ROUTES.push(...ADMIN_ROUTES);
ROUTES.push(...SETTINGS_ROUTES);
ROUTES.push(...DASHBOARD_ROUTES);
ROUTES.push(...DOCUMENTS_ROUTES);
ROUTES.push(...GENERIC_ROUTES);
ROUTES.push(...CORPORATE_ROUTES);

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        {ROUTES.map((route) => (
          <Route key={route.url} path={route.url} exact>
            {route.component}
          </Route>
        ))}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      <Toaster />
    </>
  );
}

export default App;
